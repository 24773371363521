import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Table, Tr, Th, Td, TdPrimary } from '../components/ui/table';
import { Card } from '../components/ui';
import axiosRequest from '../utils/axiosRequest';
import PaymentStatus from '../components/PaymentStatus';
import displayDate from '../utils/displayDate';
import displayAmount from '../utils/displayAmount';
import axios from 'axios';
import {
  FaClipboard,
  FaReceipt,
  FaEnvelope,
  FaPaperPlane,
  FaCcVisa,
  FaCcMastercard,
  FaCcDiscover,
  FaCcAmex,
  FaStripe,
} from 'react-icons/fa';
import Tooltip from '../components/Tooltip';
import { useQuery } from 'react-query';
import { Payment } from '../types/payment';
import TableLoading from '../components/TableLoading';

const getPayments = async () => {
  const { data } = await axios.get(process.env.API_URL + '/paymentintents');
  if (!data.payments) return [];
  const payments: Payment[] = data.payments;

  console.log(payments);
  return payments;
};

const Payments: React.FC = () => {
  const { isLoading, data, error } = useQuery('payments', getPayments);

  const renderBrand = (cardType: string) => {
    if (cardType === 'visa') return <FaCcVisa />;
    if (cardType === 'mastercard') return <FaCcMastercard />;
    if (cardType === 'discover') return <FaCcDiscover />;
    if (cardType === 'amex') return <FaCcAmex />;
    return cardType;
  };

  const renderRows = () => {
    if (!data) return null;
    return data.map((payment) => (
      <Tr key={payment.id}>
        <TdPrimary>{displayAmount(payment.amount)}</TdPrimary>
        <TdBrand>
          {renderBrand(
            payment.charges.data[0].payment_method_details.card.brand
          )}
        </TdBrand>
        <Td>
          <PaymentStatus status={payment.status} />
        </Td>
        <Td>{payment.metadata.customerName}</Td>
        <Td>{payment.metadata.invoiceNumbers}</Td>
        <Td>{displayDate(payment.created)}</Td>
        <Td>
          <Action onClick={() => navigator.clipboard.writeText(payment.id)}>
            <FaClipboard />
            <Tooltip>Copy to clipboard</Tooltip>
          </Action>
          {payment.amount ? (
            <>
              <Action
                onClick={() => window.open(payment.charges.data[0].receipt_url)}
              >
                <FaReceipt />
                <Tooltip>View receipt</Tooltip>
              </Action>
              <Action>
                <FaPaperPlane
                  style={{ cursor: 'pointer' }}
                  onClick={handleReceiptUpdate(payment.id)}
                />
                <Tooltip>Send receipt to AR</Tooltip>
              </Action>
            </>
          ) : null}
        </Td>
      </Tr>
    ));
  };

  // Update receipt to resend to AR
  const handleReceiptUpdate = (id: string) => async () => {
    try {
      const response = await axiosRequest(
        'patch',
        process.env.API_URL + '/paymentintents/' + id,
        undefined
      );
      console.log(response);
    } catch (ex) {
      console.log(ex);
    }
  };

  return (
    <Table>
      <thead>
        <Tr>
          <Th>Amount</Th>
          <Th></Th>
          <Th>Status</Th>
          <Th>Customer</Th>
          <Th>Invoices</Th>
          <Th>Date</Th>
          <Th>Actions</Th>
        </Tr>
      </thead>

      {isLoading ? <TableLoading /> : <tbody>{renderRows()}</tbody>}
    </Table>
  );
};

export default Payments;

const Action = styled.button`
  background: transparent;
  font-size: 1rem;
  color: var(--textPrimary);
  position: relative;

  div {
    display: none;
  }

  :hover {
    div {
      display: block;
    }
  }
`;

const TdBrand = styled(Td)`
  font-size: 35px;
`;
