import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axiosRequest from '../utils/axiosRequest';
import { Table, Tr, Th, Td, TdPrimary } from '../components/ui/table';
import { Fieldset, Input, Label } from '../components/ui';
import displayDate from '../utils/displayDate';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import axios from 'axios';
import TableLoading from '../components/TableLoading';
import { Stripe } from '@stripe/stripe-js';

interface Customer {
  id: string;
  created: number;
  email: string;
  name: string;
  metadata: {
    accountNumber: string;
  };
}

const getCustomers = async () => {
  const { data } = await axios.get(process.env.API_URL + '/customers');
  if (!data.customers) return [];

  const customers: Customer[] = data.customers;
  return customers;
};

const Customers: React.FC = () => {
  const [searchText, setSearchText] = useState<string>('');
  const history = useHistory();
  const { isLoading, error, data } = useQuery('customers', getCustomers);

  const renderRows = () => {
    if (!data) return null;
    return data
      .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
      .map((customer: Customer) => (
        <Tr
          onClick={() => history.push('/terminal/customers/' + customer.id)}
          key={customer.id}
        >
          <TdPrimary>{customer.name || ''}</TdPrimary>
          <Td>{customer.email}</Td>
          <Td>{customer.metadata.accountNumber || ''}</Td>
          <Td>{displayDate(customer.created)}</Td>
        </Tr>
      ));
  };

  return (
    <>
      <Actions>
        {/* <Search onSubmit={getCustomersByAccount}>
          <Fieldset>
            <Label>Search</Label>
            <Input
              onChange={e => setSearchText(e.target.value)}
              value={searchText}
            />
          </Fieldset>
        </Search> */}
      </Actions>
      <Table>
        <thead>
          <Tr>
            <Th>Customer Name</Th>
            <Th>Email</Th>
            <Th>Account Number</Th>
            <Th>Created</Th>
          </Tr>
        </thead>
        {isLoading ? <TableLoading /> : <Tbody>{renderRows()}</Tbody>}
      </Table>
      {/* <Pagination
        getNextResults={getNextResults}
        getPreviousResults={getPreviousResults}
        nextButtonDisabled={nextButtonDisabled}
        previousButtonDisabled={previousButtonDisabled}
      /> */}
    </>
  );
};

export default Customers;

const Tbody = styled.tbody`
  cursor: pointer;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Search = styled.form``;
