import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Card,
  Fieldset,
  Label,
  Input,
  ErrorMessage,
  FormGrid,
  Checkbox,
  CheckboxLabel,
  Title,
} from "../components/ui";
import { useParams, useHistory } from "react-router-dom";
import axiosRequest from "../utils/axiosRequest";
import displayDate from "../utils/displayDate";
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcDiscover,
  FaCcAmex,
  FaReceipt,
  FaClipboard,
  FaPaperPlane,
  FaStripe,
} from "react-icons/fa";
import { useForm } from "react-hook-form";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useAuth0 } from "../contexts/auth0-context";
import SubmitButton from "../components/SubmitButton";
import PaymentStatus from "../components/PaymentStatus";
import { Table, Tr, Th, Td } from "../components/ui/table";
import displayAmount from "../utils/displayAmount";
import Tooltip from "../components/Tooltip";

type FormData = {
  amount: string;
  invoiceNumbers: string;
  surcharge: string;
};

const Customer: React.FC = () => {
  const [customer, setCustomer] = useState<any>();
  const [paymentMethod, setPaymentMethod] = useState<any>();
  const [recentPayments, setRecentPayments] = useState<any[]>();
  const [success, setSuccess] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { register, handleSubmit, errors, reset, watch } = useForm<FormData>({
    mode: "onBlur",
  });
  const stripe = useStripe();
  const { user } = useAuth0();
  const [serverError, setServerError] = useState<String | null>(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  // Get Initial Customer Info
  useEffect(() => {
    const getCustomer = async () => {
      const result = await axiosRequest(
        "get",
        process.env.API_URL + "/customers/" + id,
        null
      );
      setCustomer(result.data.customer);
      console.log("[CUSTOMER]", result);
    };

    try {
      getCustomer();
    } catch (err) {
      console.log("[CUSTOMER]", err);
    }
  }, []);

  // Get Payment Method Info
  useEffect(() => {
    const getPaymentMethod = async () => {
      const result = await axiosRequest(
        "get",
        process.env.API_URL + "/paymentmethods?customer=" + id,
        null
      );
      setPaymentMethod(result.data.paymentMethods[0]);
      console.log("[PAYMENT METHODs]", result.data);
    };

    try {
      getPaymentMethod();
    } catch (err) {
      console.log("[PAYMENT METHOD]", err);
    }
  }, []);

  // Get Recent Customer Payments
  useEffect(() => {
    const getRecentPayments = async () => {
      const result = await axiosRequest(
        "get",
        process.env.API_URL + "/paymentintents?customer=" + id,
        null
      );
      setRecentPayments(result.data.payments);
      console.log("[RECENT PAYMENTS]", result);
    };

    try {
      getRecentPayments();
    } catch (err) {
      console.log("[RECENT PAYMENTS]", err);
    }
  }, [success]);

  // Main submit function
  const onSubmit = handleSubmit(async (data: any) => {
    if (!stripe) return;

    // Reset server error message
    setServerError(null);

    console.log("Creating payment intent");
    let clientSecret;
    setLoading(true);

    // Add 3 percent if surcharge
    const surcharge = parseFloat(data.surcharge);
    const calcAmount = () => {
      if (surcharge) {
        return Math.round(parseFloat(data.amount) * 100 * (1 + surcharge));
      }

      return Math.round(parseFloat(data.amount) * 100);
    };
    const body = {
      amount: calcAmount(),
      invoiceNumbers: data.invoiceNumbers,
      manual: false,
      user: user.name,
      email: customer?.email,
      accountNumber: customer?.metadata.accountNumber,
      customerName: customer?.name,
      paymentMethodId: paymentMethod?.id,
      customerId: customer?.id,
    };

    console.log(body);
    try {
      // Create payment intent -- sends amount
      const paymentIntentResponse = await axiosRequest(
        "post",
        (process.env.API_URL + "/paymentintents") as string,
        body
      );

      console.log("[paymentintentresponse]", paymentIntentResponse);

      if (paymentIntentResponse.error)
        throw new Error(paymentIntentResponse.error);
      clientSecret = paymentIntentResponse.data.clientSecret;

      // Use payment intent to finish transaction
      const paymentResult = await stripe.confirmCardPayment(clientSecret);

      if (paymentResult.error) {
        // Show error to your customer (e.g., insufficient funds)
        console.log(paymentResult.error);
        throw new Error(paymentResult?.error?.message || "Error");
      } else {
        setLoading(false);
        setSuccess(true);
        reset();
      }
    } catch (err) {
      console.log(err);
      setServerError(err.message);
      setLoading(false);
    }
  });

  const handleReceiptUpdate = (id: string) => async () => {
    try {
      const response = await axiosRequest(
        "patch",
        process.env.API_URL + "/paymentintents/" + id,
        undefined
      );

      console.log(response);
    } catch (ex) {
      console.log(ex);
    }
  };

  const renderBrand = () => {
    if (paymentMethod?.card?.brand === "visa") return <FaCcVisa />;
    if (paymentMethod?.card?.brand === "mastercard") return <FaCcMastercard />;
    if (paymentMethod?.card?.brand === "discover") return <FaCcDiscover />;
    if (paymentMethod?.card?.brand === "amex") return <FaCcAmex />;
    return paymentMethod?.card?.brand;
  };

  const renderPaymentRows = () => {
    if (!recentPayments) return null;

    return recentPayments.map((payment) => (
      <Tr
        onClick={
          payment.status === "requires_capture"
            ? () => history.push("/terminal/uncaptured")
            : undefined
        }
        key={payment.id}
      >
        <Td>{displayAmount(payment.amount_received)}</Td>
        <Td>
          <PaymentStatus status={payment.status} />
        </Td>
        <Td>{payment.metadata.invoiceNumbers}</Td>
        <Td>{displayDate(payment.created)}</Td>
        <Td>
          <Action onClick={() => navigator.clipboard.writeText(payment.id)}>
            <FaClipboard />
            <Tooltip>Copy to clipboard</Tooltip>
          </Action>
          {payment.amount_received ? (
            <>
              <Action>
                <FaReceipt
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open(payment.charges.data[0].receipt_url)
                  }
                />
                <Tooltip>View receipt</Tooltip>
              </Action>
              <Action>
                <FaPaperPlane
                  style={{ cursor: "pointer" }}
                  onClick={handleReceiptUpdate(payment.id)}
                />
                <Tooltip>Send receipt to AR</Tooltip>
              </Action>
            </>
          ) : null}
        </Td>
      </Tr>
    ));
  };

  return (
    <>
      <Title>
        {customer?.name}
        <a
          target="blank"
          href={"https://dashboard.stripe.com/customers/" + customer?.id}
        >
          <FaStripe />
        </a>
      </Title>
      <Container>
        <Wrapper>
          <Card>
            <CardTitle>ACCOUNT INFORMATION</CardTitle>
            {customer && (
              <AccountInfoGrid>
                <div>Account</div> <div>{customer?.metadata.accountNumber}</div>
                <div>Email</div> <div>{customer?.email}</div>
                <div>Created</div> <div>{displayDate(customer?.created)}</div>
              </AccountInfoGrid>
            )}
          </Card>
          <Card>
            <CardTitle>CARD INFORMATION</CardTitle>
            {paymentMethod && (
              <AccountInfoGrid>
                <div>Cardholder Name</div>
                <div>{paymentMethod.billing_details.name}</div>
                <div>Card</div>
                <CardDiv>
                  <Brand>{renderBrand()}</Brand>
                  <span>{"••••" + paymentMethod?.card.last4}</span>
                  <span>
                    {paymentMethod.card.exp_month +
                      "/" +
                      paymentMethod.card.exp_year}
                  </span>
                </CardDiv>
                <div>Added</div> <div>{displayDate(paymentMethod.created)}</div>
              </AccountInfoGrid>
            )}
          </Card>
          <Card>
            <CardTitle>CHARGE CUSTOMER</CardTitle>
            <FormGrid as="form" onSubmit={onSubmit}>
              <Fieldset>
                <Label>Invoice Number(s)</Label>
                <Input name="invoiceNumbers" ref={register()} />
                {errors.invoiceNumbers && (
                  <ErrorMessage>{errors.invoiceNumbers.message}</ErrorMessage>
                )}
              </Fieldset>
              <Fieldset>
                <Label>Amount</Label>
                <Input
                  type="number"
                  step=".01"
                  name="amount"
                  ref={register({
                    required: "Amount required.",
                    min: 0,
                  })}
                />
                {errors.amount && (
                  <ErrorMessage>{errors.amount.message}</ErrorMessage>
                )}
              </Fieldset>
              <Fieldset>
                <Label>Add Surcharge?</Label>
                <CheckboxRow>
                  <div>
                    <label htmlFor="surcharge0">0</label>
                    <input
                      type="radio"
                      name="surcharge"
                      id="surcharge0"
                      value={0}
                      ref={register()}
                    />
                  </div>
                  <div>
                    <label htmlFor="surcharge3">3%</label>
                    <input
                      type="radio"
                      name="surcharge"
                      id="surcharge3"
                      value={0.03}
                      ref={register()}
                    />
                  </div>
                  <div>
                    <label htmlFor="surcharge1.5">1.5%</label>
                    <input
                      type="radio"
                      name="surcharge"
                      id="surcharge1.5"
                      value={0.015}
                      ref={register()}
                    />
                  </div>
                </CheckboxRow>
              </Fieldset>
              <Fieldset>
                <Label>Total</Label>
                {watch().amount && (
                  <TotalBox>
                    $
                    {watch().surcharge
                      ? (
                          (parseFloat(watch().amount) *
                            100 *
                            (1 + parseFloat(watch().surcharge))) /
                          100
                        ).toFixed(2)
                      : parseFloat(watch().amount).toFixed(2)}
                  </TotalBox>
                )}
              </Fieldset>
              <SubmitButton
                loading={loading}
                disabled={!stripe || Object.keys(errors).length > 0 || loading}
                success={success}
              >
                Submit
              </SubmitButton>
              {serverError && <ErrorMessage>{serverError}</ErrorMessage>}
            </FormGrid>
          </Card>
          <Card>
            <CardTitle>RECENT</CardTitle>
            {recentPayments && (
              <Table>
                <thead>
                  <Tr>
                    <Th>AMOUNT</Th>
                    <Th>STATUS</Th>
                    <Th>INVOICE(S)</Th>
                    <Th>DATE</Th>
                    <Th>ACTIONS</Th>
                  </Tr>
                </thead>
                <tbody>{renderPaymentRows()}</tbody>
              </Table>
            )}
          </Card>
        </Wrapper>
      </Container>
    </>
  );
};

export default Customer;

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 600px minmax(600px, 1fr);
  gap: 2.4rem;
  width: 100%;
`;

const AccountInfoGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1rem;
  font-size: 0.9rem;
`;

const CardDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  gap: 0.5rem;
  max-width: 270px;
`;

const Brand = styled.div`
  position: relative;
  svg {
    position: absolute;
    left: 0;
    top: -10px;
    height: 40px;
    width: auto;
  }
`;
const CardTitle = styled.h2`
  font-weight: 600px;
  text-align: left;
  font-size: 0.9rem;
  margin-top: 0;
  border-bottom: 1px solid #d3d3d324;
  padding-bottom: 0.5rem;
`;

const Action = styled.button`
  background: transparent;
  font-size: 1rem;
  color: var(--textPrimary);
  position: relative;

  div {
    display: none;
  }

  :hover {
    div {
      display: block;
    }
  }
`;

const CheckboxRow = styled.div`
  display: flex;
  gap: 1rem;

  > div {
    display: inline-block;

    > label {
      display: block;
    }
  }
`;

const TotalBox = styled.p`
  font-weight: 600;
  background: var(--neutralLighter);
  color: white;
  padding: 0.6rem;
`;
