import React, { useState, useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router
} from 'react-router-dom';
import Login from './pages/Login';
import { useAuth0 } from './contexts/auth0-context';
import Terminal from './pages/Terminal';
import Loading from './pages/Loading';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Load Stripe
const stripePromise = () => {
  console.log(
    'Stripe:',
    process.env.STRIPE_PUBLIC ? 'key loaded' : 'key not loaded'
  );
  return loadStripe(process.env.STRIPE_PUBLIC as string);
};

const App: React.FC = () => {
  const { isAuthenticated, loginWithRedirect, loading, user } = useAuth0();

  if (
    isAuthenticated &&
    user &&
    user[`https://www.manamed.com/app_metadata`] &&
    user[`https://www.manamed.com/app_metadata`].roles
  ) {
    console.log('ROLES:', user[`https://www.manamed.com/app_metadata`].roles);
  }

  useEffect(() => {
    if (!isAuthenticated && !loading) loginWithRedirect();
  }, [loading]);

  if (isAuthenticated)
    return (
      <Router>
        <Switch>
          <Route path="/terminal">
            <Elements stripe={stripePromise()}>
              <Terminal />
            </Elements>
          </Route>
          <Route>
            <Redirect to="/terminal" />
          </Route>
        </Switch>
      </Router>
    );

  return <Loading />;
};

export default App;
