import React from 'react';
import { useAuth0 } from '../contexts/auth0-context';
import Topbar from '../components/Topbar';
import styled from 'styled-components';
import Sidebar from '../components/Sidebar';
import { Card, Title } from '../components/ui';
import { Switch, Route } from 'react-router-dom';
import NewCustomer from './NewCustomer';
import Success from './Success';
import OneTimeCharge from './OneTimeCharge';
import Customers from './Customers';
import Customer from './Customer';
import Payments from './Payments';

const Terminal: React.FC = () => {
  const { logout } = useAuth0();

  return (
    <>
      <Container>
        <Topbar />
        <Sidebar />
        <Main>
          <Switch>
            <Route path="/terminal/customers/:id">
              <Customer />
            </Route>
            <Route path="/terminal/customers">
              <Title>Customers</Title>
              <Customers />
            </Route>
            <Route path="/terminal/onetimecharge">
              <Title>One-Time Charge</Title>
              <OneTimeCharge />
            </Route>
            <Route path="/terminal/newcustomer">
              <Title>New Customer</Title>
              <NewCustomer />
            </Route>
            <Route path="/terminal/success">
              <Success />
            </Route>
            <Route path="/terminal/payments">
              <Title>Payments</Title>
              <Payments />
            </Route>
          </Switch>
        </Main>
      </Container>
    </>
  );
};

export default Terminal;

const Container = styled.div`
  display: grid;
  grid-template-columns: 280px 1fr;
  grid-template-rows: 60px 1fr;
  grid-template-areas:
    'topbar topbar'
    'sidebar main';
  min-height: 100vh;
`;

const Main = styled.div`
  padding: 2.4rem;
`;
