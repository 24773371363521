import styled from 'styled-components';

export const Table = styled.table`
  /* border: 1px solid var(--textPrimary); */
  width: 100%;
  text-align: left;
`;

export const Tbody = styled.thead``;

export const Tr = styled.tr`
  border-bottom: 1px solid var(--neutralPrimary);

  :hover {
    background: var(--neutralLight);
  }
`;

export const Th = styled.th`
  padding: 0.5rem 1rem;
  font-size: 12px;
  font-weight: 400;
  background: var(--neutralDark);
`;

export const Td = styled.td`
  padding: 0.8rem;
  font-size: 12px;
  font-weight: 400;
`;

export const TdPrimary = styled.td`
  padding: 0.8rem;
  font-size: 14px;
  font-weight: 600;
`;
