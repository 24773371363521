import React, { useState } from "react";
import styled from "styled-components";
import {
  Card,
  Fieldset,
  Label,
  Input,
  ErrorMessage,
  FormGrid,
  Checkbox,
  CheckboxLabel,
} from "../components/ui";
import { useForm } from "react-hook-form";
import { useAuth0 } from "../contexts/auth0-context";
import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import { useHistory } from "react-router-dom";
import SubmitButton from "../components/SubmitButton";
import { FaCreditCard } from "react-icons/fa";
import axiosRequest from "../utils/axiosRequest";
import { StripeCardElement } from "@stripe/stripe-js";

type FormData = {
  customerName: string;
  accountNumber: string;
  email: string;
  cardName: string;
  invoiceNumbers: string;
  amount: string;
  surcharge: string;
};

const OneTimeCharge: React.FC = () => {
  const { register, handleSubmit, errors, watch } = useForm<FormData>({
    mode: "onBlur",
  });
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useAuth0();
  const [serverError, setServerError] = useState<String | null>(null);
  const [cardElementValid, setCardElementValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [cardElementError, setCardElementError] = useState<String | null>(null);

  // Listen to change event on card element to validate
  const handleElementChange = ({ error, complete }: any) => {
    if (error) {
      setCardElementError(error.message);
      setCardElementValid(false);
    } else if (complete) {
      setCardElementError(null);
      setCardElementValid(true);
    }
  };

  // Main submit function
  const onSubmit = handleSubmit(async (data) => {
    if (!stripe || !elements) return;

    // Reset server error message
    setServerError(null);

    console.log("Creating payment intent");
    let clientSecret;
    setLoading(true);

    // Add 3 percent if surcharge
    const surcharge = parseFloat(data.surcharge);
    const calcAmount = () => {
      if (surcharge) {
        return Math.round(parseFloat(data.amount) * 100 * (1 + surcharge));
      }

      return Math.round(parseFloat(data.amount) * 100);
    };

    const body = {
      customerName: data.customerName,
      email: data.email,
      accountNumber: data.accountNumber,
      amount: calcAmount(),
      invoiceNumbers: data.invoiceNumbers,
      manual: false,
      user: user.name,
    };

    console.log(body);

    try {
      // Create payment intent -- sends amount
      const paymentIntentResponse = await axiosRequest(
        "post",
        (process.env.API_URL + "/paymentintents") as string,
        body
      );

      console.log("[paymentintentresponse]", paymentIntentResponse);

      // Use payment intent to finish transaction
      const paymentResult = await stripe.confirmCardPayment(
        paymentIntentResponse.data.clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement) as StripeCardElement,
            // billing_details: {
            //   name:
            // }
          },
        }
      );

      // Show error to your customer (e.g., insufficient funds)
      if (paymentResult.error) {
        console.log(paymentResult.error);
        throw new Error(paymentResult?.error?.message || "Error");
      }

      // On successful payment send to appropriate page
      history.push("/terminal/payments");
    } catch (err) {
      console.log(err);
      setServerError(err.message);
      setLoading(false);
    }
  });

  return (
    <Wrapper>
      <GridContainer onSubmit={onSubmit}>
        <Card>
          <FormGrid>
            <Fieldset>
              <Label>Customer Name</Label>
              <Input
                name="customerName"
                ref={register({ required: "Customer Name Required" })}
              />
              {errors.customerName && (
                <ErrorMessage>{errors.customerName.message}</ErrorMessage>
              )}
            </Fieldset>
            <Fieldset>
              <Label>Account Number</Label>
              <Input
                type="number"
                name="accountNumber"
                ref={register({
                  required: "Account number required.",
                })}
              />
              {errors.accountNumber && (
                <ErrorMessage>{errors.accountNumber.message}</ErrorMessage>
              )}
            </Fieldset>
            <Fieldset>
              <Label>Email Address</Label>
              <Input
                name="email"
                ref={register({
                  required: "Email required.",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i,
                    message: "Invalid email address.",
                  },
                })}
              />
              {errors.email && (
                <ErrorMessage>{errors.email.message}</ErrorMessage>
              )}
            </Fieldset>
          </FormGrid>
        </Card>
        <Card>
          <FormGrid>
            <Fieldset>
              <Label>Invoice Number(s)</Label>
              <Input
                name="invoiceNumbers"
                ref={register({
                  required: "Invoice numbers required.",
                })}
              />
              {errors.invoiceNumbers && (
                <ErrorMessage>{errors.invoiceNumbers.message}</ErrorMessage>
              )}
            </Fieldset>
            <Fieldset>
              <Label>Amount</Label>
              <Input
                type="number"
                step=".01"
                name="amount"
                ref={register({
                  required: "Amount required.",
                  min: 0,
                })}
              />
              {errors.amount && (
                <ErrorMessage>{errors.amount.message}</ErrorMessage>
              )}
            </Fieldset>
            <Fieldset>
              <Label>Card Info</Label>
              <CardElement
                onChange={handleElementChange}
                options={cardElementOptions}
              />
              {cardElementError && (
                <ErrorMessage>{cardElementError}</ErrorMessage>
              )}
            </Fieldset>
            <Fieldset>
              <Label>Add Surcharge?</Label>
              <CheckboxRow>
                <div>
                  <label htmlFor="surcharge0">0</label>
                  <input
                    type="radio"
                    name="surcharge"
                    id="surcharge0"
                    value={0}
                    ref={register()}
                  />
                </div>
                <div>
                  <label htmlFor="surcharge3">3%</label>
                  <input
                    type="radio"
                    name="surcharge"
                    id="surcharge3"
                    value={0.03}
                    ref={register()}
                  />
                </div>
                <div>
                  <label htmlFor="surcharge1.5">1.5%</label>
                  <input
                    type="radio"
                    name="surcharge"
                    id="surcharge1.5"
                    value={0.015}
                    ref={register()}
                  />
                </div>
              </CheckboxRow>
            </Fieldset>
            <Fieldset>
              <Label>Total</Label>
              {watch().amount && (
                <p>
                  $
                  {watch().surcharge
                    ? (
                        (parseFloat(watch().amount) *
                          100 *
                          (1 + parseFloat(watch().surcharge))) /
                        100
                      ).toFixed(2)
                    : parseFloat(watch().amount).toFixed(2)}
                </p>
              )}
            </Fieldset>
            <p>{1 + parseFloat(watch().surcharge)}</p>
            <SubmitButton
              loading={loading}
              disabled={
                !stripe ||
                Object.keys(errors).length > 0 ||
                !cardElementValid ||
                loading
              }
            >
              Submit
            </SubmitButton>
            {serverError && <ErrorMessage>{serverError}</ErrorMessage>}
          </FormGrid>
        </Card>
      </GridContainer>
      <RightSection>
        <FaCreditCard />
      </RightSection>
    </Wrapper>
  );
};

export default OneTimeCharge;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 600px 1fr;
  width: 100%;
  gap: 9rem;
`;

const cardElementOptions = {
  style: {
    base: {
      fontSize: "14px",
      color: "#e9e9e9",
      "::placeholder": {
        color: "#d3d3d34a",
      },
    },
    invalid: {
      color: "#ef516e",
    },
  },
};

const GridContainer = styled.form`
  display: grid;
  grid-template-rows: max-content max-content;
  gap: 2.4rem;
  width: 100%;
`;

const RightSection = styled.div`
  width: 100%;
  color: #ffffff0a;

  svg {
    height: 600px;
    width: auto;
  }
`;

const CheckboxRow = styled.div`
  display: flex;
  gap: 1rem;

  > div {
    display: inline-block;

    > label {
      display: block;
    }
  }
`;
