import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Card,
  Label,
  Fieldset,
  Input,
  ErrorMessage,
  FormGrid,
} from '../components/ui';
import { useForm } from 'react-hook-form';
import { loadStripe, StripeCardElement } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { FaUserPlus } from 'react-icons/fa';
import { useAuth0 } from '../contexts/auth0-context';
import axiosRequest from '../utils/axiosRequest';
import SubmitButton from '../components/SubmitButton';
import { useHistory } from 'react-router-dom';

type FormData = {
  customerName: string;
  accountNumber: string;
  email: string;
  cardName: string;
  user: string;
};

const NewCustomer: React.FC = () => {
  const { register, handleSubmit, errors } = useForm<FormData>({
    mode: 'onBlur',
  });
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useAuth0();
  const [serverError, setServerError] = useState(null);
  const [cardElementValid, setCardElementValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [cardElementError, setCardElementError] = useState(null);

  // Listen to change event on card element to validate
  const handleElementChange = ({ error, complete }: any) => {
    if (error) {
      setCardElementError(error.message);
      setCardElementValid(false);
    } else if (complete) {
      setCardElementError(null);
      setCardElementValid(true);
    }
  };

  // Main submit function
  const onSubmit = handleSubmit(async (data) => {
    if (!stripe) return;

    // Reset server error message
    setServerError(null);

    // Create setup intent
    console.log('Creating setup intent');
    let clientSecret;
    setLoading(true);
    try {
      const setupIntentResponse = await axiosRequest(
        'post',
        (process.env.API_URL + '/setupintents') as string,
        null
      );

      if (setupIntentResponse.error) throw new Error(setupIntentResponse.error);
      console.log(setupIntentResponse.data);

      clientSecret = setupIntentResponse.data.clientSecret;

      // Create payment method -- takes setupintent and card information to create paymentmethod
      console.log('Creating payment method with client secret', clientSecret);
      let paymentMethod;

      const paymentMethodResult = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements?.getElement(CardElement) as StripeCardElement,
          billing_details: {
            name: data.cardName,
          },
        },
      });
      if (paymentMethodResult.error)
        throw new Error(paymentMethodResult.error.message);
      paymentMethod = paymentMethodResult.setupIntent?.payment_method;
      console.log(paymentMethodResult);

      // Create customer -- sends customer info and paymentMethod to attach to customer
      console.log('Creating customer with payment method', paymentMethod);

      const newCustomerResponse = await axiosRequest(
        'post',
        (process.env.API_URL + '/customers') as string,
        {
          customerName: data.customerName,
          email: data.email,
          accountNumber: data.accountNumber,
          user: user.name,
          paymentMethod,
        }
      );
      console.log('New Customer Response: ', newCustomerResponse.data);
      if (newCustomerResponse.error) throw new Error(newCustomerResponse.error);

      // Reroute
      history.push('/terminal/success');
    } catch (err) {
      console.log(err);
      setServerError(err.message);
    } finally {
      setLoading(false);
    }
  });

  return (
    <Wrapper>
      <GridContainer onSubmit={onSubmit}>
        <Card>
          <FormGrid>
            <Fieldset>
              <Label>Customer Name</Label>
              <Input
                name="customerName"
                ref={register({ required: 'Customer Name Required' })}
              />
              {errors.customerName && (
                <ErrorMessage>{errors.customerName.message}</ErrorMessage>
              )}
            </Fieldset>
            <Fieldset>
              <Label>Account Number</Label>
              <Input
                type="number"
                name="accountNumber"
                ref={register({
                  required: 'Account number required.',
                })}
              />
              {errors.accountNumber && (
                <ErrorMessage>{errors.accountNumber.message}</ErrorMessage>
              )}
            </Fieldset>
            <Fieldset>
              <Label>Email Address</Label>
              <Input
                name="email"
                ref={register({
                  required: 'Email required.',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i,
                    message: 'Invalid email address.',
                  },
                })}
              />
              {errors.email && (
                <ErrorMessage>{errors.email.message}</ErrorMessage>
              )}
            </Fieldset>
          </FormGrid>
        </Card>
        <Card>
          <FormGrid>
            <Fieldset>
              <Label>Cardholder Name</Label>
              <Input
                name="cardName"
                ref={register({ required: 'Cardholder name required.' })}
              />
              {errors.cardName && (
                <ErrorMessage>{errors.cardName.message}</ErrorMessage>
              )}
            </Fieldset>
            <Fieldset>
              <Label>Card Info</Label>
              <CardElement
                onChange={handleElementChange}
                options={cardElementOptions}
              />
              {cardElementError && (
                <ErrorMessage>{cardElementError}</ErrorMessage>
              )}
            </Fieldset>
            <SubmitButton
              loading={loading}
              disabled={
                !stripe ||
                Object.keys(errors).length > 0 ||
                !cardElementValid ||
                loading
              }
            >
              Add New Customer
            </SubmitButton>
            <ErrorMessage>{serverError}</ErrorMessage>
          </FormGrid>
        </Card>
      </GridContainer>
      <RightSection>
        <FaUserPlus />
      </RightSection>
    </Wrapper>
  );
};

export default NewCustomer;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 600px 1fr;
  width: 100%;
  gap: 9rem;
`;

const GridContainer = styled.form`
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-template-rows: max-content max-content;
  gap: 2.4rem;
  width: 100%;
`;

const RightSection = styled.div`
  width: 100%;
  color: #ffffff0a;

  svg {
    height: 600px;
    width: auto;
  }
`;

const cardElementOptions = {
  style: {
    base: {
      fontSize: '14px',
      color: '#e9e9e9',
      '::placeholder': {
        color: '#d3d3d34a',
      },
    },
    invalid: {
      color: '#ef516e',
    },
  },
};
