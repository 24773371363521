import React from 'react';
import styled from 'styled-components';

const Tooltip: React.FC = ({ children }) => {
  return (
    <>
      <Tip>{children}</Tip>
      <Arrow />
    </>
  );
};

export default Tooltip;

const Tip = styled.div`
  position: absolute;
  left: -20px;
  top: -35px;
  background: white;
  color: black;
  padding: 0.5rem;
  width: auto;
  font-size: 0.7rem;
  border-radius: 4px;
  white-space: nowrap;
`;

const Arrow = styled.div`
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
  position: absolute;
  top: -7px;
  left: calc(50% - 5px);
`;
