import React from 'react';
import styled from 'styled-components';
import Symbol from '../images/logos/manamed-symbol.svg';

const TableLoading: React.FC = () => {
  return (
    <Container>
      <Logo src={Symbol}></Logo>
    </Container>
  );
};

export default TableLoading;

const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  height: auto;
  width: 200px;
  opacity: 15%;
  transform: scale(1);
  animation-direction: alternate;
  animation: pulse 2s infinite;

  @keyframes pulse {
    50% {
      transform: scale(1.5);
    }

    100% {
      transform: scale(1);
    }
  }
`;
