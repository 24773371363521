import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { IconType } from 'react-icons/lib/cjs';

interface SidebarNavItemProps {
  to: string;
  icon: IconType;
}

const SidebarNavItem: React.FC<SidebarNavItemProps> = ({
  to,
  children,
  icon
}) => {
  return (
    <Container>
      <StyledLink exact to={to}>
        <IconContainer>
          <div></div>
          {React.createElement(icon)}
        </IconContainer>
        {children}
      </StyledLink>
    </Container>
  );
};

export default SidebarNavItem;

const Container = styled.li`
  height: 100%;
`;
const StyledLink = styled(NavLink).attrs({ activeClassName: 'active' })`
  &.active {
    div {
      opacity: 1;
    }
  }
  padding-left: 1.5rem;
  height: 100%;
  color: inherit;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 400;

  :hover {
    background: var(--neutralLighter);
    color: inherit;
  }

  svg {
    height: 18px;
    width: auto;
  }

  span {
    margin-right: 0.8rem;
  }
`;

const IconContainer = styled.span`
  position: relative;

  div {
    position: absolute;
    top: auto;
    left: -14px;
    width: 4px;
    height: 30px;
    background-color: var(--themePrimary);
    opacity: 0;
    transition: opacity 300ms ease 0s;
  }
`;
