import React from 'react';
import styled from 'styled-components';
// @ts-ignore
import { WaveLoading } from 'react-loadingg';
import { FaCheck } from 'react-icons/fa';

interface SubmitButtonProps {
  loading: boolean;
  disabled: boolean;
  success?: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  children,
  loading,
  disabled,
  success
}) => {
  return (
    <Button disabled={disabled}>
      {loading && <WaveLoading color="#333333" size="small" />}
      {!loading && success && <FaCheck style={{ fontSize: '1rem' }} />}
      {!loading && !success && children}
    </Button>
  );
};

export default SubmitButton;

const Button = styled.button`
  margin-top: 19px;
  border: none;
  color: #333333;
  background: var(--themePrimary);
  height: 43px;
  position: relative;
  border-radius: 2px;
  font-weight: 600;
  font-size: 15px;

  :hover {
    background: var(--themeSecondary);
  }
`;
