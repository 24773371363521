import React, { useState } from 'react';
import styled from 'styled-components';

const ChargeStatus: React.FC<{ status: string }> = ({ status }) => {
  let color = 'gray';
  let wording = '';

  if (status === 'succeeded') {
    color = '#60a774';
    wording = 'SUCCEEDED';
  } else if (status === 'requires_capture') {
    color = '#935c9e';
    wording = 'AUTHORIZED';
  } else if (status === 'canceled') {
    wording = 'CANCELED';
  } else if (status === 'requires_payment_method') {
    color = '#e09a44';
    wording = 'FAILED';
  } else {
    wording = status;
  }

  return <Div color={color}>{wording}</Div>;
};

export default ChargeStatus;

const Div = styled.div<{ color: string }>`
  font-size: 0.7rem;
  background-color: ${props => (props.color ? props.color : 'gray')};
  color: white;
  padding: 0.3rem 0.7rem;
  display: inline-block;
  border-radius: 4px;
`;
